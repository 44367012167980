.ionApp {
  background-color: #f9f9f9;
  margin: auto;
}


@media (min-width: 480px) {
  .ionApp {
    border-radius: 8px;
    max-width: 480px;
    box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
    max-height: 1000px;
  }
}
