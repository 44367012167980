.wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 16px 0;
  padding: 0 16px;
}

.link {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.87px;
  color: #079bd1;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}
