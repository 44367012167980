html {
  height: 100vh;
}

:root {
  font-size: 16px;
  --ion-font-family: 'Roboto', sans-serif;
  --ion-background-color: #f9f9f9;
  --ion-text-color: #000000;
  --ion-color: #000000;
  --ion-card-color: #000000;

  /*  colors */
  --ion-color-primary: #079bd1;
  --ion-color-primary-rgb: 7, 155, 209;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0688b8;
  --ion-color-primary-tint: #20a5d6;

  --ion-color-blue: #009bd4;
  --ion-color-blue-rgb: 0, 155, 212;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255, 255, 255;
  --ion-color-blue-shade: #0088bb;
  --ion-color-blue-tint: #1aa5d8;

  --ion-color-dark-blue: #004e82;
  --ion-color-dark-blue-rgb: 0, 78, 130;
  --ion-color-dark-blue-contrast: #ffffff;
  --ion-color-dark-blue-contrast-rgb: 255, 255, 255;
  --ion-color-dark-blue-shade: #004572;
  --ion-color-dark-blue-tint: #1a608f;

  --ion-color-green: #46c783;
  --ion-color-green-rgb: 70, 199, 131;
  --ion-color-green-contrast: #ffffff;
  --ion-color-green-contrast-rgb: 255, 255, 255;
  --ion-color-green-shade: #3eaf73;
  --ion-color-green-tint: #59cd8f;

  --ion-color-grey: #7676801f;
  --ion-color-grey-rgb: 118,128,31;
  --ion-color-grey-contrast: #111111;
  --ion-color-grey-contrast-rgb: 17, 17, 17;
  --ion-color-grey-shade: #7676801f;
  --ion-color-grey-tint: #7676801f;

  --ion-color-purple-blue: #716eeb;
  --ion-color-purple-blue-rgb: 113, 110, 235;
  --ion-color-purple-blue-contrast: #ffffff;
  --ion-color-purple-blue-contrast-rgb: 255, 255, 255;
  --ion-color-purple-blue-shade: #6361cf;
  --ion-color-purple-blue-tint: #7f7ded;

  --ion-color-sky-blue: #3378F6;
  --ion-color-sky-blue-rgb: 51,120,246;
  --ion-color-sky-blue-contrast: #ffffff;
  --ion-color-sky-blue-contrast-rgb: 255,255,255;
  --ion-color-sky-blue-shade: #2d6ad8;
  --ion-color-sky-blue-tint: #4786f7;
}

.ion-color-blue {
  --ion-color-base: var(--ion-color-blue);
  --ion-color-base-rgb: var(--ion-color-blue-rgb);
  --ion-color-contrast: var(--ion-color-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-shade);
  --ion-color-tint: var(--ion-color-blue-tint);
}

.ion-color-dark-blue {
  --ion-color-base: var(--ion-color-dark-blue);
  --ion-color-base-rgb: var(--ion-color-dark-blue-rgb);
  --ion-color-contrast: var(--ion-color-dark-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-blue-shade);
  --ion-color-tint: var(--ion-color-dark-blue-tint);
}

.ion-color-green {
  --ion-color-base: var(--ion-color-green);
  --ion-color-base-rgb: var(--ion-color-green-rgb);
  --ion-color-contrast: var(--ion-color-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-shade);
  --ion-color-tint: var(--ion-color-green-tint);
}

.ion-color-primary {
  --ion-color-base: var(--ion-color-primary);
  --ion-color-base-rgb: var(--ion-color-primary-rgb);
  --ion-color-contrast: var(--ion-color-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-shade);
  --ion-color-tint: var(--ion-color-primary-tint);
}

.ion-color-grey {
  --ion-color-base: var(--ion-color-grey);
  --ion-color-base-rgb: var(--ion-color-grey-rgb);
  --ion-color-contrast: var(--ion-color-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-shade);
  --ion-color-tint: var(--ion-color-grey-tint);
}

.ion-color-purple-blue {
  --ion-color-base: var(--ion-color-purple-blue);
  --ion-color-base-rgb: var(--ion-color-purple-blue-rgb);
  --ion-color-contrast: var(--ion-color-purple-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple-blue-shade);
  --ion-color-tint: var(--ion-color-purple-blue-tint);
}

.ion-color-sky-blue {
  --ion-color-base: var(--ion-color-sky-blue);
  --ion-color-base-rgb: var(--ion-color-sky-blue-rgb);
  --ion-color-contrast: var(--ion-color-sky-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-sky-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-sky-blue-shade);
  --ion-color-tint: var(--ion-color-sky-blue-tint);
}

/* buttons */
.button-solid {
  --border-radius: 16px;
  --padding-top: 0;
  --padding-start: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  height: 36px;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  font-family: 'Roboto', sans-serif;
}

.button-large {
  --border-radius: 16px;
  --padding-top: 0;
  --padding-start: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  height: 50px;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  font-family: 'Roboto', sans-serif;
}

.button-small {
  --border-radius: 8px;
  --padding-top: 0;
  --padding-start: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  height: 29px;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
}

.button-clear {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
}

.button-disabled {
  opacity: 1;
  --ion-color-base: rgba(204, 207, 214, 0.7) !important;
  --ion-color-base-rgb: rgba(204, 207, 214, 0.7) !important;
  --ion-color-contrast: rgba(140, 142, 155, 0.8) !important;
  --ion-color-contrast-rgb: rgba(140, 142, 155, 0.8) !important;
  --ion-color-shade: rgba(204, 207, 214, 0.7) !important;
  --ion-color-tint: rgba(204, 207, 214, 0.7) !important;
}

/* Typography */
h3 {
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.8px;
  color: #111111;
  margin: 16px 0;
}

h4 {
  font-weight: 700;
  font-size: 26px;
  line-height: 34px;
  color: #111111;
}

/* components */
ion-content {
  --padding-bottom: 16px;
  --padding-end: 16px;
  --padding-start: 16px;
  --padding-top: 16px;
  --keyboard-offset: 16px !important;
  padding: 16px !important;
}

ion-grid {
  --ion-grid-padding: 0;
  --ion-grid-column-padding: 0;
}

.spinner {
  margin: auto;
  width: 50px;
  height: 50px;
}

.has-focus {
  border: 1px solid #079bd1 !important;
}

.native-input[disabled] {
  opacity: 1 !important;
}
